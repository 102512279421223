<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="1" class="d-md-none pb-1 pt-0" >
                    <v-icon size="16" @click="handleViewMenu" class="ml-2 mr-2">
                        fas fa-bars
                    </v-icon>
                </v-col>
                <v-col :md="6" :sm="6" cols="5" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :to="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="9" class="pt-1">
                    <span class="headline font-weight-medium">Nuevo Pedido</span>
                </v-col>
                
            </v-row>

            <v-row class="h-100">
                <v-col :md="8" :sm="12" cols="12" class="pt-0 pb-0">
                    <v-card>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col :md="5" :sm="11" cols="11">
                                        <v-autocomplete
                                            ref="customer_id"
                                            v-model="form.customer_id"
                                            :items="customers"
                                            label="Seleccione cliente"
                                            outlined
                                            :item-text="customText"
                                            item-value="id"
                                            clearable
                                            @change="handleChangeCustomer"
                                            :rules="[() => !!form.customer_id || 'Debe seleccionar un cliente']"
                                            hide-details="auto"
                                            required
                                        ></v-autocomplete>                                        
                                    </v-col>
                                    <v-col :md="1" :sm="1" cols="1" class="pl-0">
                                        <v-icon color="success" class="mt-2"
                                            @click="handleViewCreateCustomer">
                                            fas fa-plus-circle
                                        </v-icon>
                                    </v-col>
                                    <v-col :md="6" :sm="12" cols="12">
                                        <v-menu
                                            v-model="menuFrom"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    ref="order_date"
                                                    v-model="form.order_date"
                                                    label="Fecha de pedido"
                                                    readonly
                                                    clearable
                                                    v-on="on"
                                                    outlined
                                                    :rules="[() => !!form.order_date || 'Debe ingresar una fecha']"
                                                    hide-details="auto"
                                                    required
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="form.order_date" 
                                                @input="menuFrom = false" 
                                                no-title
                                                locale="es">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea v-model="form.address"
                                            ref="address"
                                            label="Dirección"
                                            outlined
                                            rows="3"
                                            :rules="[() => !!form.address || 'Debe ingresar la dirección']"
                                            hide-details="auto"
                                            required
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="8" class="pb-0 d-none d-sm-none d-md-block">
                    <v-card>
                        <v-card-title class="pt-3 pb-3">
                            <span class="subtitle-2">PRODUCTOS</span>
                            <v-spacer></v-spacer>
                            <v-btn small
                                dark
                                color="green"
                                @click="handleViewCatalogue">
                                Agregar
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th>DESCRIPCIÓN</th>
                                        <th>MARCA</th>
                                        <th>P. UNITARIO</th>
                                        <th>CANTIDAD</th>
                                        <th>SUBTOTAL</th>
                                        <th style="width: 50px"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in detail" :key="item.id">
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.brand_name }}</td>
                                        <td>{{ item.price | currency('S/') }}</td>
                                        <td>{{ item.quantity }}</td>
                                        <td>{{ item.subtotal | currency('S/') }}</td>
                                        <td>
                                            <v-icon color="error" @click="handleDeleteItem(index)">
                                                far fa-times-circle
                                            </v-icon>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" class="pb-0 d-md-none">
                    <v-card>
                        <v-card-title class="pt-2 pb-1">
                            <span class="subtitle-2 font-weight-black">PRODUCTOS</span>
                            <v-spacer></v-spacer>
                            <v-icon color="success" @click="handleViewCatalogue">
                                fas fa-plus-circle
                            </v-icon>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text class="pt-1">
                            <v-list-item v-for="(item, index) in detail" :key="item.id" class="cart-item-product pl-0 pr-0" two-line flat>
                                <template>                                
                                    <v-list-item-content>
                                        <v-list-item-title class="pay-product-name">
                                            {{ item.description | uppercase() }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="item.brand_name">
                                            <span class="mr-2 pb-1 pay-product-quantity">
                                                Marca: {{ item.brand_name }}
                                            </span>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                            <span class="mr-2 pb-1 pay-product-quantity">
                                                Cantidad: {{ item.quantity }}
                                            </span>
                                            <span class="ml-2 font-weight-bold">
                                                {{ item.subtotal | currency('S/') }}
                                            </span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action class="btn-quantity">
                                        <v-icon color="error" @click="handleDeleteItem(index)">
                                            far fa-times-circle
                                        </v-icon>
                                    </v-list-item-action>
                                </template>
                            </v-list-item>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col :md="8" :sm="12" cols="12">
                    <v-card>
                        <v-card-text class="pt-2 pb-1">
                            <p class="text-right mb-1" >
                                <span class="subtitle-1 font-weight-bold">TOTAL </span>
                                <span class="subtitle-1 font-weight-bold"> {{ total | currency('S/') }}</span>
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col :md="4" :sm="4" cols="6">
                    <v-btn block 
                        dark 
                        color="#023145"
                        :disabled="process_operation"
                        @click="handleStoreOrder">
                        Guardar
                    </v-btn>
                </v-col>
                <v-col :md="4" :sm="4" cols="6">
                    <v-btn block dark color="red"
                        @click="handleCancelOrder">
                        Cancelar
                    </v-btn>
                </v-col>
            </v-row>
        </template>

        <Catalogue ref="catalogue"></Catalogue>
        <create-update ref="createUpdate"></create-update>

        <v-snackbar v-model="cant_pay" top color="error">
            {{ message_validation }}
            <v-btn dark text @click="handleCloseMessageValidation">
                Cerrar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import router from '@/router/index';
import OfficeLogin from '../../../components/auth/OfficeLogin';
import ArrayTools from '../../../helpers/ArrayTools';

import CreateUpdate from '../../../components/customers/CreateUpdate'
import Catalogue from '../../../components/orders/Catalogue';

export default {
    name: 'CreateOrder',
    components: {
        OfficeLogin,
        Catalogue,
        CreateUpdate
    },
    data () {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Ventas',
                    disabled: true,
                    href: '',
                }
            ],
            menuFrom: false,
            cant_pay: false,
            message_validation: null,
            form: {
                customer_id: null,                
                order_date: this.$moment().format('YYYY-MM-DD'),
                address: null,
            },
            process_operation: false
        }
    },
    computed: {
        ...mapState('categories', [
            'categories'
        ]),
        ...mapState('customers', [
            'customers'
        ]),
        ...mapState('orders', [
            'detail',
            'subtotal',
            'igv',
            'discount',
            'total',
            'rounding',
            'total_rounded',
            'total_gratuitas'
        ])
    },
    methods: {
        ...mapActions('authentication', [
            'setShowMenu'
        ]),
        ...mapActions('customers', [
            'getCustomers'
        ]),
        ...mapActions('categories', [
            'getCategories',
        ]),
        ...mapActions('inventories', [
            'run_set_catalogue',
            'getCatalogue'
        ]),
        ...mapActions('orders', [
            'setDetail',
            'deleteItemFromOrder',
            'store',
            'setTotalOrder',
            'clearAmount'
        ]),
        handleViewMenu () {
            this.setShowMenu(true);
        },
        customText(item){
            let text = item.fullname
            
            return text
        },
        handleViewCreateCustomer () {
            this.$refs.createUpdate.showForm('Nuevo cliente', 'create');
        },
        handleChangeCustomer (value) {
            if (value !== undefined && value !== null) {
                let customerFounded = ArrayTools.getElementById(this.customers, value);
                let customer = Object.assign({}, customerFounded);
                this.form.address = customer.address;
            } else {
                this.form.address = null;
            }
        },
        handleViewCatalogue () {
            this.$refs.catalogue.show('create');
        },
        handleDeleteItem (index) {
            this.deleteItemFromOrder(index);
        },
        async handleStoreOrder () {
            this.process_operation = true;
            if (!this.existErrors()) {
                if (this.detail.length < 1) {
                    this.process_operation = false;
                    this.cant_pay = true;
                    this.message_validation = 'Debe ingresar los productos.';
                } else {
                    let order = {
                        order_date: this.form.order_date,
                        customer_id: this.form.customer_id,
                        address: this.form.address,
                        subtotal: this.subtotal,
                        igv: this.igv,
                        discount: this.discount,
                        total: this.total,
                        rounding: this.rounding,
                        total_rounded: this.total_rounded,
                        total_gratuitas: this.total_gratuitas,
                        products: this.detail
                    }
                    await this.store(order);
                    this.process_operation = false;
                    this.clearData();
                    this.clearAmount();
                }
            } else {
                this.process_operation = false;
            }
        },
        handleCancelOrder () {
            this.clearData();
        },
        handleCloseMessageValidation() {
            this.cant_pay = false;
            this.message_validation = null;
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.form).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.form[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.form).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
        async clearData () {
            this.setDetail([]);
            this.clearAmount();
            this.form.customer_id = null;            
            this.form.address = null;
            await this.clearErrors();
            this.form.order_date = this.$moment().format('YYYY-MM-DD');
        },
        async loadData() {
            this.setDetail([]);
            this.clearAmount();
            this.getCustomers();
            await this.getCategories();
            this.run_set_catalogue([]);
            this.getCatalogue({
                category_id: this.categories[0].id,
            });
        }
    },
    created () {
        this.loadData();
    }
}
</script>

<style scoped>

</style>